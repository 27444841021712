import React from 'react'
import {
	ToolbarOptionLink,
	ToolbarOptionLinkExterno,
	ToolbarOptionLinkAction
} from '@monorepo-amais/commons/components/Navbar/styled'

import theme from '../../theme'
//import getBrand from '../../../../commons/utils/getBrand'

// custom icons
import MenuConvenios from '@monorepo-amais/commons/components/Icons/MenuConvenios'
import MenuExames from '@monorepo-amais/commons/components/Icons/MenuExames'
import MenuPin from '@monorepo-amais/commons/components/Icons/MenuPin'
import MenuResultadoExames from '@monorepo-amais/commons/components/Icons/ResultadoExames'
import MenuNoticias from '@monorepo-amais/commons/components/Icons/IconNoticias'
import MenuFaleConosco from '@monorepo-amais/commons/components/Icons/IconFaleConosco'
//import MenuOuvidoria from '@monorepo-amais/commons/components/Icons/IconOuvidoria'
import MenuAtendimentoDomiciliar from '@monorepo-amais/commons/components/Icons/IconAtendimentoDomiciliar'

//SVG pro componente HomeOnly
import SVGConvenios from '@monorepo-amais/commons/Icons/menuConvenios.svg'
import SVGExames from '@monorepo-amais/commons/Icons/menuExames.svg'
import SVGUnidades from '@monorepo-amais/commons/Icons/pin.svg'
import SVGResultado from '@monorepo-amais/commons/Icons/menu-resultado.svg'
import SVGNoticia from '@monorepo-amais/commons/Icons/menu-noticia.svg'
import SVGFaleConosco from '@monorepo-amais/commons/Icons/fale-conosco.svg'
//import SVGOuvidoria from '@monorepo-amais/commons/Icons/menu-ouvidoria.svg'

import SVGConveniosWhite from '@monorepo-amais/commons/Icons/menuConvenios-white.svg'
import SVGExamesWhite from '@monorepo-amais/commons/Icons/menuExames-white.svg'
import SVGUnidadesWhite from '@monorepo-amais/commons/Icons/Icone_Unidades-white.svg'
import SVGResultadoWhite from '@monorepo-amais/commons/Icons/menu-resultado-white.svg'
import SVGNoticiaWhite from '@monorepo-amais/commons/Icons/menu-noticia-white.svg'
import SVGFaleConoscoWhite from '@monorepo-amais/commons/Icons/fale-conosco-white.svg'
//import SVGOuvidoriaWhite from '@monorepo-amais/commons/Icons/menu-ouvidoria-white.svg'
import SVGAtendimentoDomiciliar from '@monorepo-amais/commons/Icons/icone_Atendimento_Domiciliar.svg'

//array de itens dos menus
const setMenuItens = (isClient, urlResults) => {
	const menuItens = [
		{
			text: 'Resultado de Exames',
			href: urlResults, //pagina pra navegacao pra fora do site
			target: '_blank',
			to: '', //pagina pra navegacao dentro do site
			as: ToolbarOptionLinkExterno, //tema pro menu desk
			icon: <MenuResultadoExames theme={theme} isClient={isClient} />, //icon pro menu desk
			svg: isClient ? SVGResultado : SVGResultadoWhite, //svg pro menu mobile HomeOnly
			showMobileHome: true, //flag pra filtrar os itens da home do mobile
			showMobile: true, //flag pra filtrar os itens do menu hamburguer do mobile
			showDesk: true //flag pra filtrar os itens do desk
		},
		{
			text: 'Exames e vacinas',
			href: '',
			target: '',
			to: '/exames/',
			as: ToolbarOptionLink,
			icon: <MenuExames theme={theme} isClient={isClient} />,
			svg: isClient ? SVGExames : SVGExamesWhite,
			showMobileHome: true,
			showMobile: true,
			showDesk: true
		},
		{
			text: 'Convênios',
			href: '',
			target: '',
			to: '/convenios/',
			as: ToolbarOptionLink,
			icon: <MenuConvenios theme={theme} isClient={isClient} />,
			svg: isClient ? SVGConvenios : SVGConveniosWhite,
			showMobileHome: true,
			showMobile: true,
			showDesk: true
		},
		{
			text: 'Unidades',
			href: '',
			target: '',
			to: '/unidades/',
			as: ToolbarOptionLink,
			icon: <MenuPin theme={theme} isClient={isClient} />,
			svg: isClient ? SVGUnidades : SVGUnidadesWhite,
			showMobileHome: true,
			showMobile: true,
			showDesk: true
		}
	]

	//menus específicos pra cliente/médico
	if (isClient) {
		menuItens.push({
			text: 'Notícias',
			href: '',
			target: '',
			to: '/noticias/',
			as: ToolbarOptionLink,
			icon: <MenuNoticias theme={theme} isClient={isClient} />,
			svg: isClient ? SVGNoticia : SVGNoticiaWhite,
			showMobileHome: false,
			showMobile: true,
			showDesk: true
		})
		menuItens.push({
			text: 'Atendimento Domiciliar',
			href: '',
			target: '',
			to: '/atendimento-domiciliar/',
			as: ToolbarOptionLink,
			icon: <MenuAtendimentoDomiciliar theme={theme} isClient={isClient} />,
			svg: SVGAtendimentoDomiciliar,
			showMobileHome: true,
			showMobile: true,
			showDesk: true
		})
	} else {
		// menuItens.push({
		// 	text: 'Boletins',
		// 	href: '',
		// 	target: '',
		// 	to: '/boletim-medico/',
		// 	as: ToolbarOptionLink,
		// 	icon: <MenuNoticias theme={theme} isClient={isClient} />,
		// 	svg: isClient ? SVGNoticia : SVGNoticiaWhite,
		// 	showMobileHome: false,
		// 	showMobile: true,
		// 	showDesk: true
		// })
	}

	menuItens.push(
		{
			text: 'Institucional',
			href: '',
			target: '',
			to: '/institucional/',
			as: ToolbarOptionLink,
			icon: <MenuPin theme={theme} isClient={isClient} />,
			svg: isClient ? SVGUnidades : SVGUnidadesWhite,
			showMobileHome: false,
			showMobile: true,
			showDesk: false
		},
		{
			text: 'Fale conosco',
			href: '',
			target: '',
			to: '',
			actionClick: 'setOpenContactUsModal(true)',
			as: ToolbarOptionLinkAction,
			icon: <MenuFaleConosco theme={theme} isClient={isClient} />,
			svg: isClient ? SVGFaleConosco : SVGFaleConoscoWhite,
			showMobileHome: true,
			showMobile: true,
			showDesk: true
		},
		// {
		// 	text: 'Ouvidoria',
		// 	href: getBrand().ouvidoria,
		// 	target: '_blank',
		// 	to: '',
		// 	as: ToolbarOptionLinkExterno,
		// 	icon: <MenuOuvidoria theme={theme} isClient={isClient} />,
		// 	svg: isClient ? SVGOuvidoria : SVGOuvidoriaWhite,
		// 	showMobileHome: true,
		// 	showMobile: true,
		// 	showDesk: true
		// },
		{
			text: 'Trabalhe conosco',
			href: 'https://www.questionpro.com/t/ALQtnZes32',
			target: '_blank',
			to: '',
			as: ToolbarOptionLink,
			icon: <MenuPin theme={theme} isClient={isClient} />,
			svg: isClient ? SVGUnidades : SVGUnidadesWhite,
			showMobileHome: false,
			showMobile: true,
			showDesk: false
		}
	)

	return menuItens
}

export default setMenuItens
