const theme = {
	// breakpoints = ["576px", "768px", "992px", "1200px", "1800px"],
	container: '1366px',
	colors: {
		primary: '#009fde',
		primary_hover: '#167ea7',
		black33: '#333333',
		gray66: '#666666',
		black_fonts: '#242221',
		light_gray: '#dde1e5',
		gray1: '#343838',
		graye2: '#e2e2e2',
		graye4: '#e4e4e4',
		grayf1: '#f1f1f1',
		grayf4: '#f4f4f4',
		grayf5: '#f5f5f5',
		grayf7: '#F7F6F4',
		grayf8: '#f8f8f8',
		grayfc: '#fcfcfc',
		gray8: '#888888',
		grayb5: '#b5b5b5',
		grayf9: '#f9f9f9',
		grayab: '#ababab',
		gray55: '#555555',
		gray34: '#343838',
		gray3b: '#3b3b3b',
		grayb3: '#b3b3b3',
		grayb8: '#b8b8b8',
		gray7b: '#77787b',
		secondary: '#a9198d',
		secondary_hover: '#8b1574',
		black: '#000',
		white: '#ffffff',
		serviceBar: '#edeff4',
		serviceBarBlue: '#0088c8'
	}
}

export default theme
